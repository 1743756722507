import {Button, FormControl, Grid, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useState} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';
import InvalidIcon from '../../assets/images/invalid.svg'
import ValidIcon from '../../assets/images/valid.svg'

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(4)
    }
}));

export default function PasswordResetForm({callback, error, isLoading}) {
    const classes = useStyles();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const passwordChecks = [
        {
            regex: /.{12,}/,
            text: "Un minimum de 12 caractères"
        }, {
            regex: /[A-Z]/,
            text: "Un caractère majuscule"
        }, {
            regex: /[a-z]/,
            text: "Un caractère minuscule"
        }, {
            regex: /\d/,
            text: "Un chiffre"
        }, {
            regex: /[^a-zA-Z\d]/,
            text: "Un caractère spécial"
        }
    ]

    function isFormValid() {
        const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{12,}/
        return password.length > 0 && passwordRepeat.length > 0 && password === passwordRepeat && !isLoading && passwordRegex.test(password);
    }

    function handleSubmit(e) {
        e.preventDefault();
        callback({password});
        return false;
    }

    return <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <FormControl className={classes.formControl}>
                    <TextField label="Nouveau mot de passe" value={password} onChange={e => setPassword(e.target.value)} type="password"/>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <FormControl className={classes.formControl}>
                    <TextField label="Répéter le nouveau mot de passe" value={passwordRepeat} onChange={e => setPasswordRepeat(e.target.value)} type="password"/>
                </FormControl>
            </Grid>
        </Grid>
        {
            passwordChecks && passwordChecks.map((check) => {
                return <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid container justifyContent="left" item md={5}>
                        {check.regex.test(password) ? (
                          <img src={ValidIcon} alt='valid' />
                        ) : (
                          <img src={InvalidIcon} alt='invalid' />
                        )}
                        <Typography style={{ paddingLeft:'5px' }}>
                            { check.text }
                        </Typography>
                    </Grid>
                </Grid>
            })
        }
        <Grid container justifyContent="center" spacing={2} alignItems="center">
            <Grid item xs={12} md={5}>
                <Button type="submit" variant="contained" color="primary" fullWidth className={classes.button} disabled={!isFormValid()}>Envoyer</Button>
            </Grid>
        </Grid>
        {
            error && <Grid container justifyContent="center" spacing={2} alignItems="center">
                <Grid item xs={12} md={5}>
                    <Alert severity="error"><AlertTitle>{error}</AlertTitle></Alert>
                </Grid>
            </Grid>
        }
    </form>
}
